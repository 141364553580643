import heic2any from 'heic2any'

import { useCallback } from 'react'

import { ALLOWED_EXTENSIONS } from '~/hooks/usePrepareImage/constants'

import { getCompressedImage } from '~/utils/getCompressedImage'

export const usePrepareImage = () => {
  const convertFileHeicToJpeg = useCallback(
    (file: File): Promise<File> =>
      new Promise(async (resolve, reject) => {
        const blob = new Blob([file], { type: file.type })
        const convertedFile = (await heic2any({ blob, toType: 'image/jpeg' })) as File

        if (!convertedFile.type) {
          reject(new Error('Could not convert'))

          return
        }

        resolve(convertedFile as File)
      }),
    [],
  )

  const convertToBase64 = useCallback((file: File) => getCompressedImage(file), [])

  const validateImage = useCallback(
    (base64: string): Promise<{ extension: string; base64: string }> =>
      new Promise((resolve, reject) => {
        const extension = base64.substring('data:image/'.length, base64.indexOf(';base64'))

        if (extension && !ALLOWED_EXTENSIONS.includes(extension)) {
          reject(new Error('INVALID_EXTENSION'))

          return
        }

        resolve({ extension, base64 })
      }),
    [],
  )

  const convertToFile = useCallback(
    (base64: string, mimetype: string): Promise<File> =>
      new Promise((resolve, reject) => {
        fetch(base64)
          .then(async res => {
            const blob = await res.blob()
            const file = blob && new File([blob], 'picture.jpg', { type: mimetype })

            if (!file) {
              reject(new Error('Could not convert'))

              return
            }

            resolve(file)
          })
          .catch(e => reject(e))
      }),
    [],
  )

  return {
    validateImage,
    convertToBase64,
    convertToFile,
    convertFileHeicToJpeg,
  }
}
