import { getUserUuid } from '~/utils/getUserUuid'
import { skuHasTryOn } from '~/utils/orderEvent/utils/skuHasTryon'
import Tracking from '~/utils/tracking'

class OrderEvent {
  eventCallback = event => {
    const userId = getUserUuid()
    event.detail.products.map(product => {
      const payload = {
        appuser: event.detail.appuser,
        order: event.detail.id,
        total: event.detail.value,
        sku: product.sku,
        quantity: product.quantity,
        skuTotal: product.total,
        identifier: product.identifier || product.sku,
        tryon: skuHasTryOn(product.sku),
        currency: event.detail.currency || 'BRL',
        widget: true,
      }

      Tracking.logEvent('PURCHASE', payload)
    })

    if (userId) Tracking.setUserId(userId)
    Tracking.setUserProperties({ appuser: event.detail.appuser })

    document.removeEventListener('doris-order', this.eventCallback)
  }

  constructor() {
    document.addEventListener('doris-order', this.eventCallback)
  }
}

export default OrderEvent
