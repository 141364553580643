import posthog, { JsonType } from 'posthog-js'

import { Singleton } from '~/utils/singleton'

import { POSTHOG_HOST, POSTHOG_KEY } from './constants'
import { IPostHogSingleton, TEventData, TGetFeatureFlagResponse } from './types'

class PostHogSingleton extends Singleton<IPostHogSingleton>() {
  init() {
    posthog.init(POSTHOG_KEY, {
      api_host: POSTHOG_HOST,
      autocapture: false,
      capture_pageview: false,
      disable_session_recording: true,
    })
  }

  isFlagEnabled(featureKey: string) {
    return posthog.isFeatureEnabled(featureKey)
  }

  getFeatureFlag(featureKey: string): TGetFeatureFlagResponse {
    return posthog.getFeatureFlag(featureKey)
  }

  getFeatureFlagPayload(featureKey: string): JsonType {
    return posthog.getFeatureFlagPayload(featureKey)
  }

  startSessionRecording() {
    return posthog.startSessionRecording()
  }

  captureEvent(eventName: string, eventData: TEventData) {
    posthog.capture(eventName, eventData)
  }

  identify(id: string, data?: TEventData) {
    posthog.identify(id, data)
  }

  setUserProperties(data: TEventData) {
    posthog.setPersonProperties(data)
  }
}

export default PostHogSingleton.getInstance()
export const PostHogUtil = PostHogSingleton.getInstance()
