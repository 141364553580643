import { useCallback, useEffect, useRef, useState } from 'react'

import { useQueryProducts } from '~/hooks-queries'
import { useAutomix } from '~/hooks/useAutomix'
import { useAvatar } from '~/hooks/useAvatar'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { useMeasurements } from '~/hooks/useMeasurements'
import { usePartner } from '~/hooks/usePartner'
import { useStartProducts } from '~/hooks/useStartProducts'
import { useTryon } from '~/hooks/useTryon'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'
import { useUser } from '~/hooks/useUser'
import { UserStorageInstance } from '~/hooks/useUser/storage'

import { useHistory } from '~/context/History'
import { useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'
import { useTermsContext } from '~/context/Terms'
import { useTryonContext } from '~/context/Tryon'
import { useUserContext } from '~/context/User'
import { useWidgetState } from '~/context/WidgetState'

import { Sentry } from '~/clients/sentry'
import { BrowserUrlApi } from '~/utils/browserUrlApi'
import { getWindowDimensions } from '~/utils/getDimensions'
import { getUserUuid } from '~/utils/getUserUuid'
import Tracking from '~/utils/tracking'

import { getTryonProducts } from './utils'

const TERMS_SCREEN = false

const useStartEvent = () => {
  const { state, openWidget, ready: widgetReady } = useWidgetState()
  const { deleteCombinationList } = useAutomix()
  const { resetNavigation, navigate } = useNavigation()
  const { setStartProducts, deleteStartProducts } = useStartProducts()
  const { fetchHistoryFromStorage } = useHistory()
  const { getCurrentModel, setCurrentModel, getDefaultModels } = useCurrentModel()
  const { fetch: fetchProducts, called, refetch } = useQueryProducts({})
  const { setTryonState, stateTryon, clearStates: clearTryonState } = useTryonContext()
  const { startTryon, removeTryon, resetTryon } = useTryon()
  const { clearStates: clearMeasurementsState } = useMeasurementsContext()
  const { resetMeasurements } = useMeasurements()
  const { getAvatars } = useAvatar()
  const { setUser, createUser, resetUser } = useUser()
  const { setCurrentUserState, setUserState, stateUser, clearStates: clearUserState } = useUserContext()
  const { terms } = useTermsContext()
  const { getPartner } = usePartner()

  const [screenReady, setScreenReady] = useState({ model: false, user: false })

  const intervalKey = useRef()
  const ready = useRef()
  const tryon = useRef()

  const isWebviewRendering = BrowserUrlApi.isWebviewRendering()
  const appUserId = BrowserUrlApi.appUserId()

  const executeQuery = useCallback(
    skus => {
      const variables = {
        order_by: { id: 'asc' },
        where: {
          identifier: { _in: skus },
          active: { _eq: true },
          status: { _eq: 'PROCESSED' },
          placeholder: { _eq: false },
        },
      }

      return !called ? fetchProducts({ variables }) : /* istanbul ignore next */ refetch(variables)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [called],
  )

  const getDefaultModel = gender => {
    return getDefaultModels()[gender?.toLowerCase()] || getDefaultModels()?.male || getDefaultModels()?.female
  }

  const handleNavigate = useCallback(() => {
    setScreenReady({ user: false, model: false })

    setTimeout(() => {
      if (BrowserUrlApi.isExternalAccess()) {
        BrowserUrlApi.removeParameters({ params: ['dwoa', 'dwuuid', 'dwgender', 'dwskus', 'dwuser'] })
      }
      const isExternalUser = BrowserUrlApi.isExternalUser() ? 'InformData' : 'Home'

      if (TERMS_SCREEN) {
        navigate(!terms ? 'TermsScreen' : isExternalUser)
      } else {
        navigate(isExternalUser)
      }
    }, 500)
  }, [navigate, terms])

  const initUser = useCallback(() => {
    const user = UserStorageInstance.get()

    if (user) {
      setUser({ data: user, setState: setCurrentUserState })

      setScreenReady(current => ({ ...current, user: true }))

      return
    }

    createUser({ setState: setUserState })
  }, [createUser, setCurrentUserState, setUser, setUserState])

  const eventCallback = useCallback(
    event => {
      const { skus, defaultGender } = event.detail
      const { width, height, devicePixelRatio } = getWindowDimensions()

      setScreenReady({ user: false, model: false })

      resetNavigation()
      resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsState })

      removeTryon({ setState: clearTryonState })

      deleteStartProducts()
      deleteCombinationList()

      initUser()

      fetchHistoryFromStorage()

      localStorage.setItem('@doris:walkthrough', true)

      openWidget()

      const storageConfig = localStorage.getItem('@doris:config')
      const partnerAPIKey = localStorage.getItem('@doris:partner_apikey')

      Tracking.logEvent('START', {
        sku: skus,
        widget: true,
        webview: isWebviewRendering,
        appuser: appUserId,
        screenWidth: width,
        screenHeight: height,
        dpi: devicePixelRatio,
        position: JSON.parse(storageConfig).widget_position,
        $set: { partner_id: String(partnerAPIKey) },
      })
      Tracking.setUserId(getUserUuid())
      Tracking.setUserProperties({ appuser: appUserId })

      const eventAction = async () => {
        try {
          let currentModel = getCurrentModel()
          let products = []
          let gender = defaultGender

          if (!currentModel && !skus?.length) {
            setCurrentModel(getDefaultModel(gender))

            setScreenReady(current => ({ ...current, model: true }))

            return
          }

          if (skus?.length) {
            const response = await executeQuery(skus)

            products = !Array.isArray(response) ? response?.data?.product || [] : response

            skus.map(identifier => {
              if (products.find(product => product.identifier === identifier)) return

              // eslint-disable-next-line no-console
              console.error(`[DORIS][UseStartEvent] Error on SKU "${identifier}"`)
            })
          }

          if (!products.length) {
            if (!currentModel) {
              setCurrentModel(getDefaultModel(gender))
            }

            setScreenReady(current => ({ ...current, model: true }))

            return
          }

          gender = products.length > 1 ? products.find(item => item.category.type === 'TOP').gender : products[0].gender

          const filteredProductsByGender = products.filter(product => product.gender === gender)

          if (!currentModel || (gender !== 'UNISEX' && currentModel.gender !== gender)) {
            const avatar = getAvatars().find(avatar => avatar.gender === gender)

            if (gender === 'UNISEX' && defaultGender) {
              gender = defaultGender
            }

            currentModel =
              currentModel?.type === 'SELF_MODEL' && avatar
                ? {
                    gender: avatar.gender,
                    id: avatar.avatar_uuid,
                    stage_image: avatar.image_url,
                    type: 'SELF_MODEL',
                  }
                : getDefaultModel(gender)
          }

          setCurrentModel(currentModel)

          tryon.current = getTryonProducts(skus, filteredProductsByGender)

          const { data: partner } = await getPartner()

          startTryon({
            data: {
              idModel: currentModel.id,
              from: 'start',
              products: tryon.current,
              upscale: partner?.upscale,
            },
            setState: setTryonState,
          })

          setScreenReady(current => ({ ...current, model: true }))
        } catch (Error) {
          // eslint-disable-next-line no-console
          console.error(Error)

          Sentry.captureException({
            errorName: 'PROCESSED_ERROR',
            errorMessage: '[ERROR][USER][NEW]: An error occured',
            filePath: 'src/hooks/useStartEvent/index.js',
            functionName: 'eventAction',
          })
        }
      }

      intervalKey.current = setInterval(() => {
        if (!ready.current) return

        eventAction()

        clearInterval(intervalKey.current)
      }, 50)

      document.removeEventListener('doris-start', eventCallback)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [executeQuery],
  )

  useEffect(() => {
    if (state === 'closed') {
      document.addEventListener('doris-start', eventCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    ready.current = widgetReady
  }, [widgetReady])

  useEffect(() => {
    if (!stateTryon?.called || stateTryon?.isLoading || stateTryon?.data?.from !== 'start') return

    if (stateTryon?.error) {
      if (BrowserUrlApi.isExternalAccess()) {
        resetTryon({ data: ['stateTryon'], setState: clearTryonState })
      }

      return
    }

    if (BrowserUrlApi.isExternalAccess()) {
      TryonStorageInstance.set(stateTryon?.data?.products)
    }

    setStartProducts(tryon.current)
  }, [stateTryon, setStartProducts, resetTryon, clearTryonState])

  useEffect(() => {
    if (!stateUser?.called || stateUser?.isLoading) return

    resetUser({ data: ['stateUser'], setState: clearUserState })

    if (stateUser?.error) {
      // eslint-disable-next-line no-console
      console.error(stateUser?.error)

      Sentry.captureException({
        errorName: stateUser?.error.name,
        errorMessage: stateUser?.error.message,
        filePath: 'src/hooks/useStartEvent/index.js',
        functionName: 'start',
      })

      return
    }

    setUser({ data: stateUser.data, setState: setCurrentUserState })

    setScreenReady(current => ({ ...current, user: true }))
  }, [clearUserState, resetUser, setCurrentUserState, setUser, stateUser])

  useEffect(() => {
    if (!screenReady.model || !screenReady.user) return

    handleNavigate()
  }, [screenReady, handleNavigate])
}

export default useStartEvent
