import { GET_PRODUCTS_COMBINE } from '~/hooks-queries/products/graphql'

import { ApolloClient } from '~/clients/apollo'
import { Sentry } from '~/clients/sentry'
import { IProduct } from '~/entities'
import { dorisLog } from '~/utils/dorisLog'
import { MobileUtils } from '~/utils/mobileUtils'
import Tracking from '~/utils/tracking'

type TVerifyParams = {
  sku?: string
  apiKey: string
}

const fetchVerify = async ({ sku }: Omit<TVerifyParams, 'apiKey'>): Promise<Array<IProduct>> => {
  const response = await ApolloClient.query({
    query: GET_PRODUCTS_COMBINE,
    variables: {
      where: { identifier: { _eq: sku }, active: { _eq: true } },
    },
  })

  return response?.data?.product || []
}

export const verify = async ({ sku, apiKey }: TVerifyParams): Promise<boolean> => {
  dorisLog(`Verifying products: ${sku}`)

  const url = new URL(window.location.href)

  if (!sku) {
    Tracking.logEvent('VERIFY', {
      apiKey,
      sku,
      return: true,
      widget: true,
      url: window.location.href,
      utm_id: url.searchParams.get('utm_id'),
      utm_source: url.searchParams.get('utm_source'),
      utm_medium: url.searchParams.get('utm_medium'),
      utm_campaign: url.searchParams.get('utm_campaign'),
      utm_term: url.searchParams.get('utm_term'),
      utm_content: url.searchParams.get('utm_content'),
      device: MobileUtils.isMobile() ? 'mobile' : 'desktop',
    })
    return true
  }

  try {
    const products = await fetchVerify({ sku })

    Tracking.logEvent('VERIFY', {
      apiKey,
      sku,
      return: !!products.length,
      widget: true,
      url: window.location.href,
      utm_id: url.searchParams.get('utm_id'),
      utm_source: url.searchParams.get('utm_source'),
      utm_medium: url.searchParams.get('utm_medium'),
      utm_campaign: url.searchParams.get('utm_campaign'),
      utm_term: url.searchParams.get('utm_term'),
      utm_content: url.searchParams.get('utm_content'),
      device: MobileUtils.isMobile() ? 'mobile' : 'desktop',
      $set: { partner_id: apiKey },
    })

    return !!products.length
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)

    Sentry.captureException({
      errorName: 'PRODUCTS_FETCH_ERROR',
      errorMessage: 'Failed fetching products',
      filePath: 'src/utils/verify/index.ts',
      functionName: 'verify',
    })

    return false
  }
}
