export const downloadFile = async url => {
  const isChromeIOS = navigator.userAgent.match('CriOS')

  const blob = await (await fetch(url)).blob()

  const objectUrl = URL.createObjectURL(blob)

  if (isChromeIOS) return window.open(objectUrl)

  const anchor = document.createElement('a')
  anchor.href = objectUrl
  anchor.download = 'doris-tryon.jpeg'
  anchor.target = '_blank'
  anchor.rel = 'noopener'
  anchor.click()
}
