import { FC } from 'react'

import { AnimationFade } from '~/components/Animations'
import { Tag } from '~/components/Tag'
import { TagDisplayTrigger } from '~/components/TagDisplayTrigger'

import theme from '~/theme'

import { toCurrency } from '~/utils/toCurrency'
import Tracking from '~/utils/tracking'

import * as Styled from './styles'
import { ITag, ITagsProps } from './types'
import { useTags } from './useTags'

export const PriceTags: FC<ITagsProps> = ({ hasCrawler, onHangerClick }) => {
  const { items, shouldDisplayTags, setShouldDisplayTags } = useTags()

  const handleTracking = () => {
    Tracking.logEvent('STAGE', { action: shouldDisplayTags ? 'close' : 'open', widget: true })
  }

  const handleTag = () => {
    setShouldDisplayTags(!shouldDisplayTags)
    onHangerClick()
  }

  const renderTag = (tag: ITag): JSX.Element | null => {
    if (hasCrawler) {
      if (tag.price && tag.in_stock) {
        return (
          <AnimationFade key={`${tag.category.name}`} direction="fadeInOut" startAnimation={shouldDisplayTags}>
            <Tag icon={{ name: tag.category.name }} onClick={handleTag}>
              {toCurrency({ value: tag.price })}
            </Tag>
          </AnimationFade>
        )
      }

      return (
        <AnimationFade key={`${tag.category.name}`} direction="fadeInOut" startAnimation={shouldDisplayTags}>
          <Tag
            onClick={handleTag}
            testID={`unavailable-tag-${tag.category.type}`}
            icon={{ name: tag.category.name, color: theme.colors.palette.webOrange }}
            text={{ color: theme.colors.palette.webOrange }}
          >
            Sem estoque
          </Tag>
        </AnimationFade>
      )
    }

    if (tag.price) {
      return (
        <AnimationFade key={`${tag.category.name}`} direction="fadeInOut" startAnimation={shouldDisplayTags}>
          <Tag icon={{ name: tag.category.name }} onClick={handleTag}>
            {toCurrency({ value: tag.price })}
          </Tag>
        </AnimationFade>
      )
    }

    return null
  }

  return (
    <>
      <TagDisplayTrigger
        active={shouldDisplayTags}
        onClick={() => {
          handleTracking()
          setShouldDisplayTags(!shouldDisplayTags)
        }}
      />
      <Styled.ContainerTags data-visible={shouldDisplayTags} data-testid="container-tags">
        {items.map(tag => renderTag(tag))}
      </Styled.ContainerTags>
    </>
  )
}
