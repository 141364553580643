export const getHeaders = (headers = {}) => {
  const apiKey = localStorage.getItem('@doris:partner_apikey')

  return {
    headers: {
      ...headers,
      'x-partner-api-key': apiKey,
      'x-hasura-role': 'widget',
    },
  }
}
