import { FC, useCallback, useEffect, useState } from 'react'

import { useCombinedItems } from '~/context/CombinedItems'

import Box from '~/components/Box'
import CheckBox from '~/components/CheckBox'
import GoToProductLink from '~/components/GoToProductLink'
import { Icon } from '~/components/Icon'
import ImageItem from '~/components/ImageItem'
import Price from '~/components/Price'
import SizeSelect from '~/components/SizeSelect'
import { sizingMessagesColorsMap, sizingMessagesMap } from '~/components/SizingButton/constants'
import { TSizingMessage } from '~/components/SizingButton/types'
import { SizingLabel } from '~/components/SizingLabel'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { IProductOption } from '~/entities'
import { BrowserUrlApi } from '~/utils/browserUrlApi'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { TCombinedProductProps } from './types'

export const CombinedProduct: FC<TCombinedProductProps> = ({
  id,
  name,
  thumbnail,
  url,
  identifier,
  price,
  product_options,
  isLoading,
  category,
  hasCart,
  measurement,
  handleSizing,
}) => {
  const {
    selectedProducts,
    selectedSizes,
    handleSelectProduct,
    handleRemoveProduct,
    handleSelectProductSize,
    handleRemoveProductSize,
  } = useCombinedItems()

  const [disabled, setDisabled] = useState(false)
  const [hasStock, setHasStock] = useState(false)
  const [lastSizeUniqueCode, setLastSizeUniqueCode] = useState('0')

  const showCartOptions = hasCart && hasStock

  const isWebviewRendering = BrowserUrlApi.isWebviewRendering()

  const handleSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value !== '0') {
        Tracking.logEvent('CART_SIZE', {
          sku: identifier,
          size: event.target.options[event.target.selectedIndex].text,
          widget: true,
        })

        handleSelectProductSize([{ identifier, unique_code: event.target.value }])
        setLastSizeUniqueCode(event.target.value)
        return
      }

      handleRemoveProductSize(identifier)
      setLastSizeUniqueCode('0')
    },
    [handleRemoveProductSize, handleSelectProductSize, identifier],
  )

  const handleCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      Tracking.logEvent('CART_CHECK', {
        sku: identifier,
        type: event.target.checked ? 'select' : 'unselect',
        widget: true,
      })

      if (event.target.checked) {
        handleSelectProduct({
          id,
          name,
          thumbnail,
          url,
          identifier,
          price,
          product_options,
          category,
        })

        if (measurement?.selected) {
          handleSelectProductSize([
            {
              identifier,
              unique_code: product_options?.find(option => option.partner_size?.name === measurement.selected?.label)
                ?.unique_code as string,
            },
          ])
        } else if (lastSizeUniqueCode !== '0') {
          handleSelectProductSize([{ identifier, unique_code: lastSizeUniqueCode }])
        }

        return
      }

      handleRemoveProduct(id)
      handleRemoveProductSize(identifier)
      setDisabled(true)
    },
    [
      handleRemoveProduct,
      id,
      handleRemoveProductSize,
      identifier,
      handleSelectProduct,
      name,
      thumbnail,
      url,
      price,
      category,
      product_options,
      lastSizeUniqueCode,
      handleSelectProductSize,
      measurement,
    ],
  )

  useEffect(() => {
    if (selectedProducts.find(product => product.id === id)) setDisabled(false)
  }, [id, selectedProducts, setDisabled])

  useEffect(() => {
    setHasStock(!!product_options?.filter(option => option.has_stock).length)
  }, [product_options])

  return (
    <Styled.Container data-testid="combined-product-item">
      <Box
        borderColor={selectedSizes[identifier] ? theme.colors.neutral.black100 : theme.colors.white}
        backgroundColor={selectedSizes[identifier] ? 'transparent' : theme.colors.palette.gray95}
        isLoading={isLoading}
        borderWidth={!hasStock && !url && !isLoading ? '2px' : '2px 2px 1px 2px'}
        borderRadius={!hasStock && !url && !isLoading ? '20px' : '20px 20px 0 0'}
        padding="8px 15px"
        height="60px"
      >
        {showCartOptions && (
          <Styled.Column width="39px" padding="0 16px 0 0">
            <CheckBox handleOnChange={handleCheckbox} defaultChecked={true} />
          </Styled.Column>
        )}

        <Styled.Column width="59px" padding="0 15px 0 0">
          <ImageItem
            itemType="cart"
            id={id}
            url={`${thumbnail}?w=132`}
            onClick={() => ({})}
            disabled={disabled || !hasStock}
          />
        </Styled.Column>

        <Styled.Column
          width={showCartOptions ? 'calc(100% - 39px - 59px)' : 'calc(100% - 59px)'}
          flexDirection="column"
        >
          <Styled.Title disabled={disabled || !hasStock} data-testid="combined-product-name">
            {name}
          </Styled.Title>
          {!!price && <Price disabled={disabled} value={price} />}
          {!hasStock && <Price disabled={disabled} value={0} />}
        </Styled.Column>
      </Box>

      {(url || isLoading) && !isWebviewRendering && (
        <Styled.Column>
          <Box
            borderColor={selectedSizes[identifier] ? theme.colors.neutral.black100 : theme.colors.white}
            backgroundColor={selectedSizes[identifier] ? 'transparent' : theme.colors.palette.gray95}
            borderWidth={!hasStock ? '1px 2px 2px 2px' : '1px 2px 1px 2px'}
            isLoading={isLoading}
            height="40px"
            borderRadius={!hasStock && !isLoading ? '0 0 20px 20px' : '0'}
          >
            {url && <GoToProductLink url={url} sku={identifier} padding="0 16px" />}
          </Box>
        </Styled.Column>
      )}

      <Styled.Column>
        {(showCartOptions || isLoading) && (
          <Box
            borderColor={selectedSizes[identifier] ? theme.colors.neutral.black100 : theme.colors.white}
            backgroundColor={selectedSizes[identifier] ? 'transparent' : theme.colors.palette.gray95}
            borderWidth="1px 2px 2px 2px"
            isLoading={isLoading}
            padding="8px 16px 8px 16px"
            height="56px"
            borderRadius="0px 0px 20px 20px"
          >
            <div style={{ height: 40, width: '100%' }}>
              {measurement?.status ? (
                <TextButton
                  testID="button-select"
                  variant={measurement.selected ? 'outlined' : 'default'}
                  onClick={() => handleSizing({ category: category.type })}
                  disabled={disabled}
                >
                  {measurement.selected ? (
                    <Styled.ContainerSizeSelected>
                      <Styled.TitleSize
                        color={sizingMessagesColorsMap[measurement.selected?.fit_summary as TSizingMessage]}
                      >
                        {sizingMessagesMap[measurement.selected?.fit_summary as TSizingMessage].title}
                      </Styled.TitleSize>

                      <Styled.WrapperSize>
                        <SizingLabel
                          size="32px"
                          color={sizingMessagesColorsMap[measurement.selected?.fit_summary as TSizingMessage]}
                        >
                          {measurement.selected?.label}
                        </SizingLabel>
                        <Styled.Divider height={'22px'} width={'2px'} backgroundColor={theme.colors.neutral.black200} />
                        <Icon name={'arrowRight'} size={9} color={theme.colors.primary} />
                      </Styled.WrapperSize>
                    </Styled.ContainerSizeSelected>
                  ) : (
                    <Styled.ButtonSelectSize>
                      {translate('SELECT_SIZE')}
                      <Icon name={'arrowRight'} size={10} color={theme.colors.white} />
                    </Styled.ButtonSelectSize>
                  )}
                </TextButton>
              ) : (
                <SizeSelect
                  options={(product_options as IProductOption[]).map(option => ({
                    label: (!option?.has_stock
                      ? `${option?.partner_size?.name} (sem estoque)`
                      : option?.partner_size?.name) as string | number,
                    value: option?.unique_code as string | number,
                    disabled: !option?.has_stock,
                  }))}
                  padding="0 15px"
                  handleOnChange={handleSizeChange}
                  disabled={disabled}
                  initialOption={translate('SELECT_SIZE')}
                />
              )}
            </div>
          </Box>
        )}
      </Styled.Column>
    </Styled.Container>
  )
}
